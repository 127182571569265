/* Header */

#main-roadtrip{
    width:100%;
    height:568px;
    position: relative;
    background-repeat: no-repeat;
    background-size: cover;
    position: relative;
    z-index: 0;
}
#main-roadtrip::before{
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: -1;
}

.name-roadtrip{
    text-align: center;
	font-family: "Joyride W05 STD";
	width: 100%;
	position: absolute;
    left: 50%;
	top: 55%;
	transform: translate(-50%,-55%);
}

.name-roadtrip h1{
	font-family: "Joyride W05 STD";
	font-size: 10rem;
	margin:0px;
	/* letter-spacing: 2px; */
	color: #ffc300;
}
/* Map */


#map{
    margin-top:0px ;
	width:100%;
	height: 80vh;
	box-sizing: border-box;
	display: flex;
	align-items: center;
	justify-content: space-between;
	padding: 1% 1% 0px 0%;
	position: relative;
}

.map-text{
    width: 45%;
    color: #fff;
    list-style: circle;
}
.map-text h1{
	font-size: 3rem;
    font-family: "Joyride W05 STD";
	color: #ffc300;
    font-weight: 500;
    margin: 0px;
	padding: 0px;
}
.map-text p{
    width: 80%;
	font-size: 1.1rem;
	color:#cecece;
    font-family: "Joyride W05 STD";
	
}
.map-text button{
    margin-top: 20px;
	width: 140px;
	height: 45px;
	border-radius: 10px;
    font-weight: bold;
	border: none;
    outline: none;
    color: #ffffff;
	background-color: #ffc300;
    font-family: 'Lato';
}
.map-text button:hover{
    box-shadow: 5px 10px 30px white;
	background-color: rgb(0, 0, 0);
    transition: all ease 0.3s;
    color: #ffffff;
}
.map-image{
    width: 70%;
    padding-left: 5%;
}
.map-image img{
  width: 800px;
}

.bail h1{
	font-family: "Joyride W05 STD";
    text-align: center;
	font-size: 5rem;
    margin-bottom: 20px;
	/* letter-spacing: 2px; */
	color: #ffc300;
}
.txt {
    padding: 5px;
}

#obj{
    width:100%;
    height:70vh;
    box-sizing: border-box;
    font-family:'Lato';
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    background-color: #ffc300;
    /* padding-top: 40px ; */
    /* background-image: url('../images/feuille.jpeg'); /* Chemin vers votre image */
    /* background-size: cover; /* Taille de l'image pour couvrir tout le div */
    /* background-position: center; Position de l'image au centre */ 
}
.a-container-obj{
    display: flex;
    justify-content: center;
    align-content: center;

}
.a-box-obj{
    width:450px;
    height: 530px;
    margin: 20px;
    border-radius: 10px;
    /* overflow: hidden;
    transition: 0.4s ease-in-out; */
    position: relative;
}

.a-b-text-obj img{
    padding: 15px;
    width:30%;
    height: 40%;

}
.a-box-obj:hover{
    border: 1px solid white;
    box-shadow: 2px 2px 12px whitesmoke;
}
.a-b-text-obj{
    width: 100%;
    height: 100%;
    /* background-image: url('../images/ima1.jpeg'); */
    /* background-size: cover; 
    background-position: center;  */
    display:flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    flex-direction: column;
}

.a-b-text-obj h2{
    color: #fff;
    font-family: "Joyride W05 STD";
}
.a-b-text-obj p{
    margin: 0px;
    color:white;
    padding: 20px;
    font-size: 1.2rem;
    font-family: "Joyride W05 STD";
    max-width: 100%;
    margin-top: 5px;
    -webkit-line-clamp: 4;
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-overflow: ellipsis;
    margin-bottom: 10px;
}
.a-b-text-obj p:hover{
    color:#ffffff;
}
.a-b-text-obj a{
    margin-top:15px ;
}

/* Média */

@media(max-width:1190px){
	#main-roadtrip{
		background-size: 1150px !important;
	}
    #map{
		height:auto;
    }
    .name-roadtrip{
		left: 50%;
		top: 50%;
        transform: translate(-50%,-50%);
        text-align: center;
    }
    .a-container-obj{
		flex-wrap: wrap;
	}
    .a-box-obj{
		flex-grow: 1;
	}
    .a-b-img-obj img{
		object-fit:contain;
    }
    #obj{
		height:auto;
    }
    .map-image img{ 
		height:400px;
    }
    .bail h1{
        font-size: 2rem;
    }
    .a-box-obj{
        width: 100%;
        height:550px;
    }
}
@media(max-width:970px){
    .name-roadtrip h1 {
        font-size: 3rem;
    }
    .name-roadtrip{
		left: 50%;
		top: 50%;
		transform: translate(-50%,-50%);
    }
    #map{
		justify-content: center;
        padding-top: 0px;
        text-align: center;
        margin-top: 50px;
	}
	.map-text{
        width: 90%;
	}
	.map-text h1{
		font-size:1.3rem;
    }
    .map-text p{
        width: 100%;
    }
    .map-image{
        display:none;
    }
    .a-b-text-obj p{
        font-size: 0.9rem;
    }
}


@media(max-width:400px){
    .a-box-obj{
        width: 100%;
        height:300px;
    }
    .name-roadtrip{
		width: 60%;
    }
    /* .a-box-offres{
        width: 100%;
        height:200px;
    } */
}

