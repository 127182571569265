
/* Header */

#main-offres{
    width:100%;
    height:568px;
    position: relative;
    background-repeat: no-repeat;
    background-size: cover;
    /* background-image: url('../images/banner-offre.jpeg'); */
    position: relative;
    z-index: 0;
}
#main-offres::before{
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: -1;
}

.name-offres{
    text-align: center;
	font-family: "Joyride W05 STD";
	width: 100%;
	position: absolute;
    left: 50%;
	top: 55%;
	transform: translate(-50%,-55%);
}

.name-offres h1{
	font-family: "Joyride W05 STD";
	font-size: 6rem;
	margin:0px;
	/* letter-spacing: 2px; */
	color: #ffc300;
}


/* About */

#about-offres{
    margin-top:20px ;
	width:100%;
	height: 80vh;
	box-sizing: border-box;
	display: -webkit-box;
	align-items: center;
	justify-content: space-between;
	padding: 50px 5% 0px 5%;
	position: relative;
}

.about-text-offres{
    width: 75%;
    color: #fff;
    list-style: circle;
}
.about-text-offres h1{
	font-size: 3rem;
	color: #ffc300;
    font-weight: 500;
    margin: 0px;
	padding: 0px;
}
.about-text-offres p{
    width: 80%;
	font-size: 1.2rem;
	color:#cecece;
	
}
.about-text-offres button{
    margin-top: 20px;
	width: 140px;
	height: 45px;
	border-radius: 10px;
    font-weight: bold;
	border: none;
    outline: none;
    color: #ffffff;
	background-color:#ffc300;
    font-family: "Joyride W05 STD";
}
.about-text-offres button:hover{
    box-shadow: 5px 10px 30px rgba(255, 2, 2, 0.336);
	background-color: rgb(0, 0, 0);
    transition: all ease 0.3s;
    color: #ffffff;
}
.about-image-offres{
    width: 30%;
}
.about-image-offres img{
  width: 350px;
  border-radius: 20px;
}

video {
    pointer-events: none; 
}
/* Compteur */

#compteur{
    width:100%;
    height:50vh;
    box-sizing: border-box;
    font-family:'Lato';
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    /* padding-top: 40px ; */
    background-color: #ffc300;
}
#compteur h1{
    color: #fff;
    font-size: 3rem;
}
.a-container-compteur{
    display: flex;
    justify-content: center;
    align-content: center;

}
.a-box-compteur{
    background-color: #000000;
    width:380px;
    height: 350px;
    margin: 20px;
    border-radius: 10px;
    overflow: hidden;
    transition: 0.4s ease-in-out;
    position: relative;
}
.a-b-img-compteur{
    width: 100%;
    height: 45%;

}
.a-b-text-compteur img{
    padding: 15px;
    width:30%;
    height: 50%;

}
.a-box-compteur:hover{
    border: 1px solid white;
    box-shadow: 2px 2px 12px whitesmoke;
}
.a-b-text-compteur{
    width: 100%;
    height: 100%;
    background-color:#000000;
    display:flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    flex-direction: column;
}
.a-b-text-compteur h2{
    color: #fff;
}
.a-b-text-compteur p{
    margin: 0px;
    color:#ffffffa6;
    font-size: 1rem;
    font-family:'Lato';
    display: block;
    display: -webkit-box;
    max-width: 100%;
    margin-top: 5px;
    -webkit-line-clamp: 4;
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-overflow: ellipsis;
    margin-bottom: 10px;
}
.a-b-text-compteur p:hover{
    color:#ffffff;
}
.a-b-text-compteur a{
    margin-top:15px ;
}

.offer-btn-compteur{
	width:120px;
    font-family: 'Lato';
	height: 40px;
	display: flex;
	justify-content: center;
    align-items: center;
    font-size: 1.2rem;
    background-color: #ffc300;
    border-radius: 5px;
	color:#ffffff;
    margin-bottom: 15px;
}
.offer-btn-compteur:hover{
    transition: all ease 0.5s;
    color: whitesmoke;
    border: 2px solid black;
}
/* .offer-header-btns-compteur{
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    margin-top: 30px;
} */


/* pop UP */
.popup {
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    z-index: 1000;
    background: #121212;
    color: whitesmoke;
    padding: 20px;
    border-radius: 5px;
    box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.5); /* Ombre pour l'effet de flou */
  }
  
  
  .overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.5); /* Fond semi-transparent noir pour l'effet de flou */
    z-index: 999; /* Z-index inférieur à celui du pop-up pour qu'il soit en arrière-plan */
  }



#contact-compteur{
    width:100%;
    display:flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
}
#contact-compteur h1{
    color: #fff;
    font-size: 3rem;
}
#contact-compteur form{
    width:600px;
    display:flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin-bottom: 20px;
}
#contact-compteur form input,
#contact-compteur form textarea{
    width:100%;
    height:30px;
    margin:5px 0px;
    padding: 10px;
    border: none;
    outline: none;
    background-color: #ffffff2d;
    color: #ffffff;
    border-radius: 5px;
}
#contact-compteur form textarea{
    height: 100px;
}
#contact-compteur form input[type="submit"]{
    height: 45px;
    background: #ffc300;
    color: #ffffff;
    text-transform: uppercase;
}
#contact-compteur form label{
    color: #ffffff;
    text-align: left;
}
  
/* Features */

#features-offres{
    width:100%;
    height:auto;
    box-sizing: border-box;
    font-family:'Lato';
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    /* padding-top: 40px ; */
    background-color: #000000;
}

#features-offres h1{
    color: #fff;
    font-size: 3rem;
    text-align: center;
    font-family: "Joyride W05 STD";
}
#features-offres h1 .gardien{
    color: #ffc300;
    font-size: 2.3rem;
    text-align: center;
    font-family: "Joyride W05 STD";
}
#features-offres h1 .bonmalin{
    color: wheat;
    font-size: 2.5rem;
    text-align: center;
    font-family: "Calligraffitti";
}
.a-container-offres{
    display: flex;
    justify-content: center;
    align-content: center;
    padding: 20px;

}
.a-box-offres{
    background-color: #000000;
    width:100%;
    height: 100%;
    margin: 20px;
    border-radius: 10px;
    overflow: hidden;
    transition: 0.4s ease-in-out;
    position: relative;
}
.a-b-img-offres{
    width: 100%;
    /* height: 100%; */
}

.a-box-offres:hover{
    border: 1px solid white;
    box-shadow: 2px 2px 12px whitesmoke;
}
.a-b-text-offres{
    width: 100%;
    /* height: 50%; */
    background-color:#000000;
    display:flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    flex-direction: column;
    padding: 5px;
}
.a-b-text-offres h2{
    color: #fff;
}
.a-b-text-offres p{
    margin: 0px;
    color:#ffffffa6;
    font-size: 1rem;
    font-family:'Lato';
    display: block;
    display: -webkit-box;
    max-width: 100%;
    padding: 5px;
    -webkit-line-clamp: 4;
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-overflow: ellipsis;
    margin-bottom: 10px;
}
.a-b-text-offres p:hover{
    color:#ffffff;
}
.a-b-text-offres a{
    margin-top:15px ;
}


@media(max-width:1190px){
	.name-offres{
		left: 50%;
		top: 50%;
        transform: translate(-50%,-50%);
        text-align: center;
    }
    .name-offres h2{
        font-size: 1.5rem;
    }
    .name-offres h1{
        font-size: 2rem;
    }
    #about-offres{
		height:auto;
        margin-bottom: 50px;
    }
    .about-image-offres img{
		height:400px;
    }
    .a-container-compteur{
		flex-wrap: wrap;
	}
    .a-box-compteur{
		flex-grow: 1;
	}
    .a-b-img-compteur img{
		object-fit:contain;
    }
    #compteur{
		height:auto;
    }
    .a-container-offres{
		flex-wrap: wrap;
	}
    .a-box-offres{
		flex-grow: 1;
	}
    .a-b-img-offres img{
		object-fit:contain;
    }
    #features-offres{
		height:auto;
    }
    
}

@media(max-width:970px){
	.name-offres{
		left: 50%;
		top: 50%;
		transform: translate(-50%,-50%);
    }
    .about-image-offres{
        display:none;
    }
    #about-offres{
		justify-content: center;
        padding-top: 30px;
        text-align: center;
        margin-top: 0px;
	}
	.about-text-offres{
        width: 90%;
	}
	.about-text-offres h1{
		font-size:4rem;
    }
    .about-text-offres p{
        width: 100%;
    }
    #features-offres{
        margin-bottom: 100px;
        margin-top: 20px;
    }
    #features-offres h1{
        color: #fff;
        font-size: 1.4rem;
        text-align: center;
    }
    #features-offres h1 .gardien{
        color: #ffc300;
        font-size: 1.2rem;
        text-align: center;
        font-family: "Joyride W05 STD";
    }
    #features-offres h1 .bonmalin{
        color: wheat;
        font-size: 1.5rem;
        text-align: center;
        font-family: "Calligraffitti";
    }
    #contact-compteur form {
        width: 300px;
    }
}

@media(max-width:400px){
    .a-box-compteur{
        width: 100%;
        height:300px;
    }
    /* .a-box-offres{
        width: 100%;
        height:200px;
    } */
}
        