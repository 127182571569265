@import url(https://fonts.googleapis.com/css2?family=Lato:wght@300;400;700&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Calligraffitti:wght@300;400;700&display=swap);
/* @font-face {
    font-family: "Joyride W05 STD";
    src: url("https://db.onlinewebfonts.com/t/406b56da0c31245c249b3a3c61560d1a.eot");
    src: url("https://db.onlinewebfonts.com/t/406b56da0c31245c249b3a3c61560d1a.eot?#iefix")format("embedded-opentype"),
    url("https://db.onlinewebfonts.com/t/406b56da0c31245c249b3a3c61560d1a.woff2")format("woff2"),
    url("https://db.onlinewebfonts.com/t/406b56da0c31245c249b3a3c61560d1a.woff")format("woff"),
    url("https://db.onlinewebfonts.com/t/406b56da0c31245c249b3a3c61560d1a.ttf")format("truetype"),
} */

@font-face {
    font-family: "Joyride W05 STD";
    src: url(/static/media/406b56da0c31245c249b3a3c61560d1a.406b56da.ttf)format("truetype"),
}

/* ajout de elian en test effet */
/* feature effet */
@keyframes vibrate {
    0%, 100% {
      transform: translateY(0);
    }
    25%, 75% {
      transform: translateY(-5px);
    }
    50% {
      transform: translateY(5px);
    }
  }
  
.a-container {
    display: flex;
    justify-content: space-between;
    margin: 50px;
}

.a-container-social {
    display: flex;
    justify-content: space-between;
    margin: 50px;
}

.a-box {
    width: 50px;
    height: 50px;
    background-color: #3498db;
    margin: 10px;
}

/* button effet */
@keyframes pulse {
    0%, 100% {
      transform: scale(1);
    }
    50% {
      transform: scale(1.1);
    }
  }
  
.header-btns {
    transition: transform 0.3s ease;
    transform-style: preserve-3d;
}     

.header-btns:hover {
    transform: perspective(500px) translateZ(50px);
}

.header-btnsroadtrip {
    transition: transform 0.3s ease;
    transform-style: preserve-3d;
}     
.header-btnsroadtrip:hover {
    transform: perspective(500px) translateZ(50px);
}
.offer-header-btns:hover {
    transform: perspective(500px) translateZ(50px);
}
  
/* fin de l'ajout de elian en test effet */
    
body{
    margin:0px;
    padding: 0px;
    background-color: black;
    font-family: 'Lato', sans-serif;
}
*{
    box-sizing: border-box;
}
ul{
    list-style: none;
}
a{
    text-decoration: none;
}
button{
    outline: none;
    border: none;
}
input{
    outline: none;
    border: none;
}
.logo img{
    width: 72%;
    padding: 5px;
    /* animation: rotate 15s linear infinite; */
}

@keyframes rotate {
    from {
      transform: rotate(0deg);
    }
    to {
      transform: rotate(360deg);
    }
}

#main{
    width:100%;
    height:768px;
    position: relative;
    background-repeat: no-repeat;
    background-size: cover;
    background-image: url(/static/media/banner.623089c6.jpeg);
    position: relative;
    z-index: 0;
}
#main::before{
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: -1;
}

#mainroadtrip{
    width:100%;
    height:700px;
    position: relative;
    background-repeat: no-repeat;
    background-size: cover;
    background-image: url(/static/media/roadtrip.0aac8a35.jpeg);
    position: relative;
    z-index: 0;
}
#mainroadtrip::before{
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: -1;
}

.nameroadtrip{
    text-align: center;
	font-family: 'Montserrat';
	width: 1000px;
	position: absolute;
    left: 50%;
	top: 25%;
	transform: translate(-50%,-55%);
    border: 2px #ffc300 solid;
    padding: 20px;
    transition: margin-left 0.3s ease;
}
.nameroadtrip:hover {
    margin-left: 20px; /* Déplacement du contenu lorsqu'on survole */
  }
.nameroadtrip span{
    color: #ffc300;
}
.nameroadtrip h2{
	font-size: 2rem;
	margin:0px;
	letter-spacing: 2px;
	color:#ffc300;
    font-family: "Joyride W05 STD";
}
.nameroadtrip h1{
	font-size: 8rem;
	margin:0px;
	letter-spacing: 2px;
	color:#ffc300;
    font-family: "Joyride W05 STD";
}
.header-btnroadtrip{
	width:160px;
    font-family: 'Lato';
	height: 50px;
	display: flex;
	justify-content: center;
    align-items: center;
    font-size: 1.4rem;
    background-color: black;
    box-shadow: 5px 10px 30px whitesmoke;
    border-radius: 5px;
	color:#ffffff;
}
.header-btnroadtrip:hover{
    transition: all ease 0.5s;
    color: whitesmoke;
    border: 2px solid black;
}
.header-btnsroadtrip{
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    margin-top: 0px;
}

nav{
	display: flex;
	justify-content: space-around;
	align-items: center;
	position: fixed;
	left: 0;
	top: 0;
	width:100%;
	z-index: 1;
    background-color: transparent;
    transition: 0.6s ease-in-out;
}
nav.active{
	box-shadow: 5px 10px 30px rgba(0, 0, 0, 0.157);
	background-color: #000000;
}
nav ul{
	display: flex;
}
.active{
    background-color: #FF1414;
    color:#ffffff;
	box-shadow: 5px 10px 30px white;
	transition: all ease 0.2s;
}
.active:hover{
    background-color: #000000;
    color:#ffffff;
	box-shadow: 5px 10px 30px white;
	transition: all ease 0.2s;
}
nav ul li a{
    font-family: "Joyride W05 STD";
	height:40px;
	line-height: 43px;
	margin: 3px;
	padding: 0px 22px;
	display: flex;
	font-size: 0.9rem;
	text-transform: uppercase;
	font-weight: 500;
	color:whitesmoke;
    letter-spacing: 1px;
    border-radius: 3px;
    transition: 0.2s ease-in-out;
}
nav ul li a:hover{
	background-color: whitesmoke;
    color:#000000;
	box-shadow: 5px 10px 30px whitesmoke;
	transition: all ease 0.2s;
}
nav .menu-btn,
.menu-icon{
		display:none;
	}
.name{
    text-align: center;
	font-family: 'Montserrat';
	width: 900px;
	position: absolute;
    left: 50%;
	top: 55%;
	transform: translate(-50%,-55%);
}
.name span{
    color: #ffc300;
}
.name h2{
	font-family:'Lato';
	font-size: 2.7rem;
	margin:0px;
	letter-spacing: 2px;
	color:#ffffff;
}
.name h1{
	font-family:'Lato';
	font-size: 5rem;
	margin:0px;
	letter-spacing: 2px;
	color:#ffffff;
}
.header-btns{
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    margin-top: 20px;
}
.header-btn{
	width:160px;
    font-family: 'Lato';
	height: 50px;
	display: flex;
	justify-content: center;
    align-items: center;
    font-size: 1.4rem;
    background-color: black;
    box-shadow: 5px 10px 30px whitesmoke;
    border-radius: 5px;
	color:#ffffff;
}
.header-btn:hover{
    transition: all ease 0.5s;
    color: whitesmoke;
    border: 2px solid black;
}

.offer-btn{
	width:160px;
    font-family: 'Lato';
	height: 50px;
	display: flex;
	justify-content: center;
    align-items: center;
    font-size: 1.2rem;
    background-color: #ffc300;
    /* box-shadow: 5px 5px 12px whitesmoke; */
    border-radius: 5px;
	color:#ffffff;
}
.offer-btn:hover{
    transition: all ease 0.5s;
    color: whitesmoke;
    border: 2px solid black;
}
.offer-header-btns{
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    margin-top: 30px;
}

#features{
    width:100%;
    height:120vh;
    box-sizing: border-box;
    font-family:'Lato';
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding-top: 40px ;
    background-image: url(/static/media/wood.7fff1db3.jpeg);
}
#features h1{
    color: #ffc300;
    font-family: "Joyride W05 STD";
    font-size: 2.5rem;
}
.a-container{
    display: flex;
    justify-content: center;
    align-content: center;

}
/* .a-box{
    background-color: #000000;
    width:280px;
    height: 550px;
    margin: 20px;
    border-radius: 10px;
    overflow: hidden;
    transition: 0.4s ease-in-out;
    position: relative;
} */
.a-b-img{
    width: 100%;
    height: 45%;

}
.a-b-img img{
    padding: 15px;
    margin-top: 40px;
    width:100%;
    height: 50%;

}
.a-box:hover{
    border: 1px solid white;
    box-shadow: 2px 2px 12px whitesmoke;
}
.a-b-text{
    width: 100%;
    height: 55%;
    background-color:#000000;
    display:flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    flex-direction: column;
}
.a-b-text h2{
    color: #fff;
}
.a-b-text p{
    margin: 0px;
    color:#ffffffa6;
    font-size: 1rem;
    font-family:'Lato';
    display: block;
    display: -webkit-box;
    max-width: 100%;
    margin-top: 5px;
    -webkit-line-clamp: 4;
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-overflow: ellipsis;
    margin-bottom: 10px;
}
.a-b-text p:hover{
    color:#ffffff;
}
.a-b-text a{
    margin-top:15px ;
}

#presentaion{
    padding-top: 30px;
    width:100%;
    height:768px;
    position: relative;
    background-repeat: no-repeat;
    background-size: cover;
    text-align: center;
    z-index: -1; 
}

video {
    width: 100%;
    height: 100%;
    object-fit: cover;
}
  
.pr-heading{
    text-align: center;
	font-family:'Lato';
	width: 600px;
	position: absolute;
    left: 50%;
	top: 55%;
	transform: translate(-50%,-55%);
}
.pr-heading span{
    color: #FF1414;
}
.pr-heading .details{
    font-size: 23px;
    color: #c5c5c5;
}
.pr-heading .details{
    font-size: 1.2rem;
}
.pr-heading h1{
	font-family:'Lato';
	font-size: 4rem;
	margin:0px;
	letter-spacing: 2px;
	color:#ffffff;
}
.pr-btns{
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    margin-top: 20px;
}
.pr-btn{
	width:160px;
	height: 50px;
	display: flex;
	justify-content: center;
    align-items: center;
    font-size: 1.4rem;
    background-color: #FF1414;
    box-shadow: 5px 10px 30px white;
    border-radius: 5px;
	color:#ffffff;
}
.pr-btn:hover{
	background-color: transparent;
    transition: all ease 0.5s;
    color: #ffffff;
    border: 2px solid #FF1414;
}

#about{
    margin-top:0px ;
	width:100%;
	height: 100vh;
	box-sizing: border-box;
	display: flex;
	align-items: center;
	justify-content: space-between;
	padding: 50px 1% 0px 5%;
	position: relative;
}

.about-text{
    width: 55%;
    color: #fff;
    list-style: circle;
}
.about-text h1{
	font-size: 2.5rem;
    font-family: "Joyride W05 STD";
	color: #ffc300;
    font-weight: 500;
    margin: 0px;
	padding: 0px;
}
.about-text p{
    width: 80%;
	font-size: 1.1rem;
	color:#cecece;
    font-family: "Joyride W05 STD";
	
}

.gardienp {
    color: #ffc300;
    font-style: italic;
}
.about-text button{
    margin-top: 20px;
	width: 140px;
	height: 45px;
	border-radius: 10px;
    font-weight: bold;
	border: none;
    outline: none;
    color: #ffffff;
	background-color: #ffc300;
    font-family: 'Lato';
}
.about-text button:hover{
    box-shadow: 5px 10px 30px white;
	background-color: rgb(0, 0, 0);
    transition: all ease 0.3s;
    color: #ffffff;
}
.about-image{
    width: 50%;
}
.about-image img{
  width: 600px;
}

/* Bandeau */

.bandeau-container {
    overflow: hidden;
    width: 100%;
    height: 100px; /* Hauteur du bandeau */
    background-color: #ffc300;
    color: #ffffff;
    display: flex;
    align-items: center; 
    font-size: 1.5rem;
    font-family: "Joyride W05 STD";
  }
  
  .bandeau {
    display: flex;
    animation: defilement 20s linear infinite; /* Animation de défilement */
  }
  
  .bandeau-item {
    flex: 0 0 50%; /* Répartir les noms en 3 colonnes */
    text-align: center;
  }
  
  @keyframes defilement {
    0% {
      transform: translateX(0%); /* Départ */
    }
    100% {
      transform: translateX(-100%); /* Déplacement */
    }
  }

/* bandeaubis */

#bandeaubis {
    width: 100%;
    height: 30vh; /* Hauteur du bandeau */
    background-color: #000000;
    color: #ffc300;
    display: flex;
    /* align-items: center;  */
    font-size: 1.5rem;
    font-family: "Joyride W05 STD";
  }
.bandeaubis h1 {
    padding: 15px;
}
/* Aboutroad */

#aboutroad{
    margin-top:0px ;
	width:100%;
	height: 100vh;
	box-sizing: border-box;
	display: flex;
	align-items: center;
	justify-content: space-between;
	padding: 50px 1% 0px 1%;
	position: relative;
}

#notfound{
    margin-top:0px ;
	width:100%;
	height: 100vh;
	box-sizing: border-box;
	display: flex;
	align-items: center;
	justify-content: space-between;
	padding: 50px 1% 0px 1%;
	position: relative;
}

.aboutroad-text{
    width: 55%;
    color: #cecece;
    list-style: circle;
    font-family: "Joyride W05 STD";
    margin-left: 8%;
}
.aboutroad-text h1{
	font-size: 2.3rem;
    font-family: "Joyride W05 STD";
	color: #ffc300;
    font-weight: 500;
    margin: 0px;
	padding: 0px;
}
.aboutroad-text p{
    width: 80%;
	font-size: 1.1rem;
	color:#cecece;
	
}
.aboutroad-text button{
    margin-top: 20px;
	width: 140px;
	height: 45px;
	border-radius: 10px;
    font-weight: bold;
	border: none;
    outline: none;
    color: #ffffff;
	background-color: #ffc300;
    font-family: 'Lato';
}
.aboutroad-text button:hover{
    box-shadow: 5px 10px 30px white;
	background-color: rgb(0, 0, 0);
    transition: all ease 0.3s;
    color: #ffffff;
}
.aboutroad-video{
    width: 35%;
}
.aboutroad-video video{
  width: 400px;
  border-radius: 20px 20px 20px 20px;
-webkit-border-radius: 20px 20px 20px 20px;
-moz-border-radius: 20px 20px 20px 20px;
  
}
  



/* Contact */

#contact{
    padding-top: 30px;
    width:100%;
    display:flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    font-family: "Joyride W05 STD";
}
#contact h1{
    color: #ffc300;
    font-size: 3rem;
    font-family: "Joyride W05 STD";
}
#contact form{
    width:600px;
    display:flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin-bottom: 40px;
    font-family: "Joyride W05 STD";
}
#contact form input,
#contact form textarea{
    width:100%;
    height:50px;
    margin:5px 0px;
    padding: 10px;
    border: none;
    outline: none;
    background-color: #ffffff2d;
    color: #ffffff;
    border-radius: 5px;
    font-family: "Joyride W05 STD";
}
#contact form textarea{
    height: 150px;
}
#contact form input[type="submit"]{
    height: 45px;
    background: #ffc300;
    color: #ffffff;
    text-transform: uppercase;
    border: 2px solid transparent;
}
#contact form input[type="submit"]:hover{
    background-color: rgb; /* Change la couleur de fond lors du survol */
    transition: background-color 0.3s ease;
    border-color: #ffffff;
}
#contact form label{
    color: #ffffff;
    text-align: left;
}

.notification {
    position: fixed;
    top: 0;
    right: -100%;
    width: 100%;
    background-color: #ffc300;
    filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#000000",endColorstr="#ffc300",GradientType=1);
    color: #fff;
    padding: 25px;
    text-align: center;
    font-weight: bold;
    animation: slide-in 0.5s forwards;
    z-index: 1000; /* ou toute valeur plus élevée que le reste du contenu */
  }
  
  @keyframes slide-in {
    to {
      right: 0;
    }
  }
  
  


.social-post {
    display:flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    background: "black";
}

.social-box{
    background-color: #000000;
    width:100%;
    height: 100%;
    margin: 20px;
    border-radius: 10px;
    overflow: hidden;
    transition: 0.4s ease-in-out;
    position: relative;
}

#social{
    width:100%;
    height:120vh;
    box-sizing: border-box;
    font-family:'Lato';
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding-top: 40px ;
    background-color: #000000;
}
#social h1{
    color: #fff;
    font-size: 3rem;
}

/*  Footer  */
.footer {
    background: rgb(0,0,0);
    filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#000000",endColorstr="#ffc300",GradientType=1);
    color: #fff;
    padding: 30px 0;
    text-align: center; /* Centrer le texte dans le footer */
    margin-top: 100px;
    font-family: "Joyride W05 STD";
  }
  
  .footer-content {
    max-width: 1200px;
    margin: 0 auto; /* Centrer le contenu horizontalement */
    display: flex;
    flex-wrap: wrap; /* Permet aux éléments de passer à la ligne sur mobile */
    justify-content: space-around;
  }
  
  .footer-section {
    flex: 1 0 300px; /* Réduire la largeur à 100% sur mobile, avec une largeur maximale de 300px */
    margin: 10px; /* Espacement entre les sections */
  }
  
  h2 {
    color: #fff;
  }
  
  ul {
    list-style: none;
    padding: 0;
  }
  
  ul li {
    margin-bottom: 8px;
  }
  
  .social-icons a {
    color: #fff;
    margin-right: 10px;
    font-size: 24px;
  }
  
  .footer-bottom {
    margin: 20px;
    font-size: 0.7em;
  }
  
  
  
@media(max-width:620px){
    #contact form{
        width:90%;
    }
}

@media(max-width:1190px){
	#main{
		background-size: 1150px !important;
		
	}
	.nameroadtrip{
		left: 50%;
		top: 20%;
        transform: translate(-50%,-50%);
        text-align: center;
    }
    .nameroadtrip {
        width: 90%;
    }
    .nameroadtrip h2{
        font-size: 0.7rem;
    }
    .nameroadtrip h1{
        font-size: 3rem;
    }
    .nameroadtrip .details{
        font-size: 1rem;
    }
    .name{
		left: 50%;
		top: 50%;
        transform: translate(-50%,-50%);
        text-align: center;
    }
    .name h2{
        font-size: 1.5rem;
    }
    .name h1{
        font-size: 3rem;
    }
    .name .details{
        font-size: 1rem;
    }
    .pr-heading{
		left: 50%;
		top: 50%;
        transform: translate(-50%,-50%);
        text-align: center;
    }
    .pr-heading h2{
        font-size: 1.5rem;
    }
    .pr-heading h1{
        font-size: 3rem;
    }
    .pr-heading .details{
        font-size: 1rem;
    }
	.about-image img{
		height:400px;
    }
    .aboutroad-video video{
		height:400px;
    }
    #bandeaubis{
		height:auto;
        width: auto;
        font-size: 0.8;
    }
    .bandeaugen {
        height: auto;
    }

    #features{
		height:auto;
    }
    #about{
		height:auto;
    }
    #aboutroad{
		height:auto;
    }
    #notfound{
        height:auto;
    }
    #social{
		height:auto;
    }
    .a-container{
		flex-wrap: wrap;
	}
    .a-container-social{
		flex-wrap: wrap;
	}
    .a-box{
		flex-grow: 1;
	}
    .a-b-img img{
		object-fit:contain;
    }
}

@media(max-width:970px){
	#main{
		height: 650px;
	}
	.name{
		left: 50%;
		top: 50%;
		transform: translate(-50%,-50%);
    }
    .pr-heading{
		left: 50%;
		top: 50%;
		transform: translate(-50%,-50%);
	}
	.about-image{
        display:none;
    }
    .aboutroad-video{
        display:none;
    }
    
    .header-btn{
        margin-top: 20px;
    }
    .pr-btn{
        margin-left: 20%;
        margin-top: 20px;
    }
	#about{
		justify-content: center;
        padding: 0;
        text-align: center;
        margin-top: 30px;
	}
	.about-text{
        width: 90%;
        margin-top: 50px;
	}
	.about-text h1{
		font-size:1.3rem;
    }
    .about-text p{
        width: 100%;
    }
    #aboutroad{
		justify-content: center;
        padding-top: 0px;
        text-align: center;
        margin-top: 50px;
        margin-bottom: 50px;
	}
    #notfound{
		justify-content: center;
        padding-top: 0px;
        text-align: center;
        margin-top: 150px;
        margin-bottom: 50px;
	}
	.aboutroad-text{
        width: 90%;
        margin-left: 0;
	}
	.aboutroad-text h1{
		font-size:1.3rem;
    }
    .aboutroad-text p{
        width: 100%;
    }
    #contact h1 {
        text-align: center;
        font-size: 2rem;
        padding: 3%;
    }
    #features{
        margin-bottom: 100px;
    }
    #about{
        margin-bottom: 50px;
    }
    #presentaion{
        margin-bottom: 100px;
    }
    #social{
        margin-bottom: 100px;
    }
    #features h1 {
        font-size: 1.8rem;
    }
    .bandeaubis h1{
        font-size: 1.3rem;
    }
}
@media(max-width:600px){
	.name{
		width:60%;
    }
    .pr-heading{
		width:60%;
    }
    #features h1 {
        font-size: 1.2rem;
    }
}

@media(max-width:1100px){.menu-icon{
    display:block;}
        nav{
            justify-content: space-between;
            height: 75px;
            padding: 0px 30px;
        }
        .logo img{
            width:25%;
        }
        .header-btns{
            margin: 0;
        }
        .offer-header-btns{
            margin: 0px;
        }
        .pr-btns{
            margin: 0;
        }
        .menu{
            display:none;
            position:absolute;
            top:65px;
            left:0px;
            background-color: rgba(51, 51, 51, 0.5);
            border-bottom:4px solid whitesmoke;
            width:100%;
            padding:0px;
            margin:0px;
        }
        .menu li{
            width:100%;
        }
        nav .menu li a{
            width:100%;
            height:40px;
            justify-content: center;
            align-items: center;
            margin:0px;
            padding: 25px;
            border:1px solid rgba(38,38,38,0.03);
        }
        nav .menu-icon{
            cursor:pointer;
            float:right;
            padding:28px 20px;
            position:relative;
            -webkit-user-select: none;
                    user-select: none;
        }
        nav .menu-icon .nav-icon{
            background-color:#ffffff;
            display:block;
            height:2px;
            position:relative;
            transition: background 0.2s ease-out;
            width:24px;
        }
        nav .menu-icon .nav-icon:before,
        nav .menu-icon .nav-icon:after{
            background:rgb(255, 255, 255);
            content:'';
            display:block;
            height:100%;
            position:absolute;
            transition:all ease-out 0.2s;
            width:100%;
        }
        nav .menu-icon .nav-icon:before{
            top:6px;
        }
        nav .menu-icon .nav-icon:after{
            top:-6px;
        }
        nav .menu-btn:checked ~ .menu-icon .nav-icon{
            background:transparent;
        }
        nav .menu-btn:checked ~ .menu-icon .nav-icon:before{
            transform: rotate(-45deg);
            top:0;
        }
        nav .menu-btn:checked ~ .menu-icon .nav-icon:after{
            transform: rotate(45deg);
            top:0;
        }
        nav .menu-btn{
            display:none;
        }
        nav .menu-btn:checked ~ .menu{
            display:block;
        }}
        @media(max-width:400px){
            .a-box{
                width: 100%;
                height:500px;
            }
        }
/* Header */

#main-galerie{
    width:100%;
    height:568px;
    position: relative;
    background-repeat: no-repeat;
    background-size: cover;
    background-image: url(/static/media/banner-contact.91750b26.jpeg);
    position: relative;
    z-index: 0;
}
#main-galerie::before{
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: -1;
}

.name-galerie{
    text-align: center;
	font-family: 'Montserrat';
	width: 900px;
	position: absolute;
    left: 50%;
	top: 55%;
	transform: translate(-50%,-55%);
}

.name-galerie h1{
	font-family: "Joyride W05 STD";
	font-size: 3rem;
	margin:0px;
	/* letter-spacing: 2px; */
	color: #ffc300;
}

/* Features */

#features-galerie{
    width:100%;
    height:120vh;
    box-sizing: border-box;
    font-family:'Lato';
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding-top: 40px ;
    background-color: #000000;
}
#features-galerie h1{
    color: #fff;
    font-size: 3rem;
}
.a-container-galerie{
    display: flex;
    justify-content: center;
    align-content: center;

}
.a-box-galerie{
    background-color: #000000;
    width:100%;
    height: 100%;
    margin: 20px;
    border-radius: 10px;
    overflow: hidden;
    transition: 0.4s ease-in-out;
    position: relative;
}
.a-b-img-galerie{
    width: 100%;
    height: 100%;
}

.a-b-img-galerie img{
    padding: 15px;
    margin-top: 40px;
    width:100%;
    height: 50%;

}
.a-box-galerie:hover{
    border: 1px solid white;
    box-shadow: 2px 2px 12px whitesmoke;
}
.a-b-text-galerie{
    width: 100%;
    height: 40%;
    background-color:#000000;
    display:flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    flex-direction: column;
}
.a-b-text-galerie h2{
    color: #fff;
}
.a-b-text-galerie p{
    margin: 0px;
    color:#ffffffa6;
    font-size: 1rem;
    font-family:'Lato';
    display: block;
    display: -webkit-box;
    max-width: 100%;
    margin-top: 5px;
    -webkit-line-clamp: 4;
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-overflow: ellipsis;
    margin-bottom: 10px;
}
.a-b-text-galerie p:hover{
    color:#ffffff;
}
.a-b-text-galerie a{
    margin-top:15px ;
}

/* Média */

@media(max-width:1190px){
	#main-galerie{
		background-size: 1150px !important;
		
	}
	.name-galerie{
		left: 50%;
		top: 50%;
        transform: translate(-50%,-50%);
        text-align: center;
    }
    
    .name-galerie h1{
        font-size: 2rem;
    }
    .name-galerie h2{
        font-size: 1.5rem;
    }
    .name-galerie .details{
        font-size: 1rem;
    }

    #features-galerie{
		height:auto;
    }
    .a-container-galerie{
		flex-wrap: wrap;
	}
    .a-box-galerie{
		flex-grow: 1;
	}
    .a-b-img-galerie img{
		object-fit:contain;
    }
}

@media(max-width:970px){
	#main-galerie{
		height: 650px;
	}
	.name-galerie{
		left: 50%;
		top: 50%;
		transform: translate(-50%,-50%);
    }
    
    #features-galerie{
        margin-bottom: 100px;
    }
}
@media(max-width:600px){
	.name-galerie{
		width:60%;
    }
}



/* Header */

#main-offres{
    width:100%;
    height:568px;
    position: relative;
    background-repeat: no-repeat;
    background-size: cover;
    /* background-image: url('../images/banner-offre.jpeg'); */
    position: relative;
    z-index: 0;
}
#main-offres::before{
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: -1;
}

.name-offres{
    text-align: center;
	font-family: "Joyride W05 STD";
	width: 100%;
	position: absolute;
    left: 50%;
	top: 55%;
	transform: translate(-50%,-55%);
}

.name-offres h1{
	font-family: "Joyride W05 STD";
	font-size: 6rem;
	margin:0px;
	/* letter-spacing: 2px; */
	color: #ffc300;
}


/* About */

#about-offres{
    margin-top:20px ;
	width:100%;
	height: 80vh;
	box-sizing: border-box;
	display: -webkit-box;
	align-items: center;
	justify-content: space-between;
	padding: 50px 5% 0px 5%;
	position: relative;
}

.about-text-offres{
    width: 75%;
    color: #fff;
    list-style: circle;
}
.about-text-offres h1{
	font-size: 3rem;
	color: #ffc300;
    font-weight: 500;
    margin: 0px;
	padding: 0px;
}
.about-text-offres p{
    width: 80%;
	font-size: 1.2rem;
	color:#cecece;
	
}
.about-text-offres button{
    margin-top: 20px;
	width: 140px;
	height: 45px;
	border-radius: 10px;
    font-weight: bold;
	border: none;
    outline: none;
    color: #ffffff;
	background-color:#ffc300;
    font-family: "Joyride W05 STD";
}
.about-text-offres button:hover{
    box-shadow: 5px 10px 30px rgba(255, 2, 2, 0.336);
	background-color: rgb(0, 0, 0);
    transition: all ease 0.3s;
    color: #ffffff;
}
.about-image-offres{
    width: 30%;
}
.about-image-offres img{
  width: 350px;
  border-radius: 20px;
}

video {
    pointer-events: none; 
}
/* Compteur */

#compteur{
    width:100%;
    height:50vh;
    box-sizing: border-box;
    font-family:'Lato';
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    /* padding-top: 40px ; */
    background-color: #ffc300;
}
#compteur h1{
    color: #fff;
    font-size: 3rem;
}
.a-container-compteur{
    display: flex;
    justify-content: center;
    align-content: center;

}
.a-box-compteur{
    background-color: #000000;
    width:380px;
    height: 350px;
    margin: 20px;
    border-radius: 10px;
    overflow: hidden;
    transition: 0.4s ease-in-out;
    position: relative;
}
.a-b-img-compteur{
    width: 100%;
    height: 45%;

}
.a-b-text-compteur img{
    padding: 15px;
    width:30%;
    height: 50%;

}
.a-box-compteur:hover{
    border: 1px solid white;
    box-shadow: 2px 2px 12px whitesmoke;
}
.a-b-text-compteur{
    width: 100%;
    height: 100%;
    background-color:#000000;
    display:flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    flex-direction: column;
}
.a-b-text-compteur h2{
    color: #fff;
}
.a-b-text-compteur p{
    margin: 0px;
    color:#ffffffa6;
    font-size: 1rem;
    font-family:'Lato';
    display: block;
    display: -webkit-box;
    max-width: 100%;
    margin-top: 5px;
    -webkit-line-clamp: 4;
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-overflow: ellipsis;
    margin-bottom: 10px;
}
.a-b-text-compteur p:hover{
    color:#ffffff;
}
.a-b-text-compteur a{
    margin-top:15px ;
}

.offer-btn-compteur{
	width:120px;
    font-family: 'Lato';
	height: 40px;
	display: flex;
	justify-content: center;
    align-items: center;
    font-size: 1.2rem;
    background-color: #ffc300;
    border-radius: 5px;
	color:#ffffff;
    margin-bottom: 15px;
}
.offer-btn-compteur:hover{
    transition: all ease 0.5s;
    color: whitesmoke;
    border: 2px solid black;
}
/* .offer-header-btns-compteur{
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    margin-top: 30px;
} */


/* pop UP */
.popup {
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    z-index: 1000;
    background: #121212;
    color: whitesmoke;
    padding: 20px;
    border-radius: 5px;
    box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.5); /* Ombre pour l'effet de flou */
  }
  
  
  .overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.5); /* Fond semi-transparent noir pour l'effet de flou */
    z-index: 999; /* Z-index inférieur à celui du pop-up pour qu'il soit en arrière-plan */
  }



#contact-compteur{
    width:100%;
    display:flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
}
#contact-compteur h1{
    color: #fff;
    font-size: 3rem;
}
#contact-compteur form{
    width:600px;
    display:flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin-bottom: 20px;
}
#contact-compteur form input,
#contact-compteur form textarea{
    width:100%;
    height:30px;
    margin:5px 0px;
    padding: 10px;
    border: none;
    outline: none;
    background-color: #ffffff2d;
    color: #ffffff;
    border-radius: 5px;
}
#contact-compteur form textarea{
    height: 100px;
}
#contact-compteur form input[type="submit"]{
    height: 45px;
    background: #ffc300;
    color: #ffffff;
    text-transform: uppercase;
}
#contact-compteur form label{
    color: #ffffff;
    text-align: left;
}
  
/* Features */

#features-offres{
    width:100%;
    height:auto;
    box-sizing: border-box;
    font-family:'Lato';
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    /* padding-top: 40px ; */
    background-color: #000000;
}

#features-offres h1{
    color: #fff;
    font-size: 3rem;
    text-align: center;
    font-family: "Joyride W05 STD";
}
#features-offres h1 .gardien{
    color: #ffc300;
    font-size: 2.3rem;
    text-align: center;
    font-family: "Joyride W05 STD";
}
#features-offres h1 .bonmalin{
    color: wheat;
    font-size: 2.5rem;
    text-align: center;
    font-family: "Calligraffitti";
}
.a-container-offres{
    display: flex;
    justify-content: center;
    align-content: center;
    padding: 20px;

}
.a-box-offres{
    background-color: #000000;
    width:100%;
    height: 100%;
    margin: 20px;
    border-radius: 10px;
    overflow: hidden;
    transition: 0.4s ease-in-out;
    position: relative;
}
.a-b-img-offres{
    width: 100%;
    /* height: 100%; */
}

.a-box-offres:hover{
    border: 1px solid white;
    box-shadow: 2px 2px 12px whitesmoke;
}
.a-b-text-offres{
    width: 100%;
    /* height: 50%; */
    background-color:#000000;
    display:flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    flex-direction: column;
    padding: 5px;
}
.a-b-text-offres h2{
    color: #fff;
}
.a-b-text-offres p{
    margin: 0px;
    color:#ffffffa6;
    font-size: 1rem;
    font-family:'Lato';
    display: block;
    display: -webkit-box;
    max-width: 100%;
    padding: 5px;
    -webkit-line-clamp: 4;
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-overflow: ellipsis;
    margin-bottom: 10px;
}
.a-b-text-offres p:hover{
    color:#ffffff;
}
.a-b-text-offres a{
    margin-top:15px ;
}


@media(max-width:1190px){
	.name-offres{
		left: 50%;
		top: 50%;
        transform: translate(-50%,-50%);
        text-align: center;
    }
    .name-offres h2{
        font-size: 1.5rem;
    }
    .name-offres h1{
        font-size: 2rem;
    }
    #about-offres{
		height:auto;
        margin-bottom: 50px;
    }
    .about-image-offres img{
		height:400px;
    }
    .a-container-compteur{
		flex-wrap: wrap;
	}
    .a-box-compteur{
		flex-grow: 1;
	}
    .a-b-img-compteur img{
		object-fit:contain;
    }
    #compteur{
		height:auto;
    }
    .a-container-offres{
		flex-wrap: wrap;
	}
    .a-box-offres{
		flex-grow: 1;
	}
    .a-b-img-offres img{
		object-fit:contain;
    }
    #features-offres{
		height:auto;
    }
    
}

@media(max-width:970px){
	.name-offres{
		left: 50%;
		top: 50%;
		transform: translate(-50%,-50%);
    }
    .about-image-offres{
        display:none;
    }
    #about-offres{
		justify-content: center;
        padding-top: 30px;
        text-align: center;
        margin-top: 0px;
	}
	.about-text-offres{
        width: 90%;
	}
	.about-text-offres h1{
		font-size:4rem;
    }
    .about-text-offres p{
        width: 100%;
    }
    #features-offres{
        margin-bottom: 100px;
        margin-top: 20px;
    }
    #features-offres h1{
        color: #fff;
        font-size: 1.4rem;
        text-align: center;
    }
    #features-offres h1 .gardien{
        color: #ffc300;
        font-size: 1.2rem;
        text-align: center;
        font-family: "Joyride W05 STD";
    }
    #features-offres h1 .bonmalin{
        color: wheat;
        font-size: 1.5rem;
        text-align: center;
        font-family: "Calligraffitti";
    }
    #contact-compteur form {
        width: 300px;
    }
}

@media(max-width:400px){
    .a-box-compteur{
        width: 100%;
        height:300px;
    }
    /* .a-box-offres{
        width: 100%;
        height:200px;
    } */
}
        
/* Header */

#main-roadtrip{
    width:100%;
    height:568px;
    position: relative;
    background-repeat: no-repeat;
    background-size: cover;
    position: relative;
    z-index: 0;
}
#main-roadtrip::before{
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: -1;
}

.name-roadtrip{
    text-align: center;
	font-family: "Joyride W05 STD";
	width: 100%;
	position: absolute;
    left: 50%;
	top: 55%;
	transform: translate(-50%,-55%);
}

.name-roadtrip h1{
	font-family: "Joyride W05 STD";
	font-size: 10rem;
	margin:0px;
	/* letter-spacing: 2px; */
	color: #ffc300;
}
/* Map */


#map{
    margin-top:0px ;
	width:100%;
	height: 80vh;
	box-sizing: border-box;
	display: flex;
	align-items: center;
	justify-content: space-between;
	padding: 1% 1% 0px 0%;
	position: relative;
}

.map-text{
    width: 45%;
    color: #fff;
    list-style: circle;
}
.map-text h1{
	font-size: 3rem;
    font-family: "Joyride W05 STD";
	color: #ffc300;
    font-weight: 500;
    margin: 0px;
	padding: 0px;
}
.map-text p{
    width: 80%;
	font-size: 1.1rem;
	color:#cecece;
    font-family: "Joyride W05 STD";
	
}
.map-text button{
    margin-top: 20px;
	width: 140px;
	height: 45px;
	border-radius: 10px;
    font-weight: bold;
	border: none;
    outline: none;
    color: #ffffff;
	background-color: #ffc300;
    font-family: 'Lato';
}
.map-text button:hover{
    box-shadow: 5px 10px 30px white;
	background-color: rgb(0, 0, 0);
    transition: all ease 0.3s;
    color: #ffffff;
}
.map-image{
    width: 70%;
    padding-left: 5%;
}
.map-image img{
  width: 800px;
}

.bail h1{
	font-family: "Joyride W05 STD";
    text-align: center;
	font-size: 5rem;
    margin-bottom: 20px;
	/* letter-spacing: 2px; */
	color: #ffc300;
}
.txt {
    padding: 5px;
}

#obj{
    width:100%;
    height:70vh;
    box-sizing: border-box;
    font-family:'Lato';
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    background-color: #ffc300;
    /* padding-top: 40px ; */
    /* background-image: url('../images/feuille.jpeg'); /* Chemin vers votre image */
    /* background-size: cover; /* Taille de l'image pour couvrir tout le div */
    /* background-position: center; Position de l'image au centre */ 
}
.a-container-obj{
    display: flex;
    justify-content: center;
    align-content: center;

}
.a-box-obj{
    width:450px;
    height: 530px;
    margin: 20px;
    border-radius: 10px;
    /* overflow: hidden;
    transition: 0.4s ease-in-out; */
    position: relative;
}

.a-b-text-obj img{
    padding: 15px;
    width:30%;
    height: 40%;

}
.a-box-obj:hover{
    border: 1px solid white;
    box-shadow: 2px 2px 12px whitesmoke;
}
.a-b-text-obj{
    width: 100%;
    height: 100%;
    /* background-image: url('../images/ima1.jpeg'); */
    /* background-size: cover; 
    background-position: center;  */
    display:flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    flex-direction: column;
}

.a-b-text-obj h2{
    color: #fff;
    font-family: "Joyride W05 STD";
}
.a-b-text-obj p{
    margin: 0px;
    color:white;
    padding: 20px;
    font-size: 1.2rem;
    font-family: "Joyride W05 STD";
    max-width: 100%;
    margin-top: 5px;
    -webkit-line-clamp: 4;
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-overflow: ellipsis;
    margin-bottom: 10px;
}
.a-b-text-obj p:hover{
    color:#ffffff;
}
.a-b-text-obj a{
    margin-top:15px ;
}

/* Média */

@media(max-width:1190px){
	#main-roadtrip{
		background-size: 1150px !important;
	}
    #map{
		height:auto;
    }
    .name-roadtrip{
		left: 50%;
		top: 50%;
        transform: translate(-50%,-50%);
        text-align: center;
    }
    .a-container-obj{
		flex-wrap: wrap;
	}
    .a-box-obj{
		flex-grow: 1;
	}
    .a-b-img-obj img{
		object-fit:contain;
    }
    #obj{
		height:auto;
    }
    .map-image img{ 
		height:400px;
    }
    .bail h1{
        font-size: 2rem;
    }
    .a-box-obj{
        width: 100%;
        height:550px;
    }
}
@media(max-width:970px){
    .name-roadtrip h1 {
        font-size: 3rem;
    }
    .name-roadtrip{
		left: 50%;
		top: 50%;
		transform: translate(-50%,-50%);
    }
    #map{
		justify-content: center;
        padding-top: 0px;
        text-align: center;
        margin-top: 50px;
	}
	.map-text{
        width: 90%;
	}
	.map-text h1{
		font-size:1.3rem;
    }
    .map-text p{
        width: 100%;
    }
    .map-image{
        display:none;
    }
    .a-b-text-obj p{
        font-size: 0.9rem;
    }
}


@media(max-width:400px){
    .a-box-obj{
        width: 100%;
        height:300px;
    }
    .name-roadtrip{
		width: 60%;
    }
    /* .a-box-offres{
        width: 100%;
        height:200px;
    } */
}


