/* Header */

#main-galerie{
    width:100%;
    height:568px;
    position: relative;
    background-repeat: no-repeat;
    background-size: cover;
    background-image: url('../images/banner-contact.jpeg');
    position: relative;
    z-index: 0;
}
#main-galerie::before{
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: -1;
}

.name-galerie{
    text-align: center;
	font-family: 'Montserrat';
	width: 900px;
	position: absolute;
    left: 50%;
	top: 55%;
	transform: translate(-50%,-55%);
}

.name-galerie h1{
	font-family: "Joyride W05 STD";
	font-size: 3rem;
	margin:0px;
	/* letter-spacing: 2px; */
	color: #ffc300;
}

/* Features */

#features-galerie{
    width:100%;
    height:120vh;
    box-sizing: border-box;
    font-family:'Lato';
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding-top: 40px ;
    background-color: #000000;
}
#features-galerie h1{
    color: #fff;
    font-size: 3rem;
}
.a-container-galerie{
    display: flex;
    justify-content: center;
    align-content: center;

}
.a-box-galerie{
    background-color: #000000;
    width:100%;
    height: 100%;
    margin: 20px;
    border-radius: 10px;
    overflow: hidden;
    transition: 0.4s ease-in-out;
    position: relative;
}
.a-b-img-galerie{
    width: 100%;
    height: 100%;
}

.a-b-img-galerie img{
    padding: 15px;
    margin-top: 40px;
    width:100%;
    height: 50%;

}
.a-box-galerie:hover{
    border: 1px solid white;
    box-shadow: 2px 2px 12px whitesmoke;
}
.a-b-text-galerie{
    width: 100%;
    height: 40%;
    background-color:#000000;
    display:flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    flex-direction: column;
}
.a-b-text-galerie h2{
    color: #fff;
}
.a-b-text-galerie p{
    margin: 0px;
    color:#ffffffa6;
    font-size: 1rem;
    font-family:'Lato';
    display: block;
    display: -webkit-box;
    max-width: 100%;
    margin-top: 5px;
    -webkit-line-clamp: 4;
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-overflow: ellipsis;
    margin-bottom: 10px;
}
.a-b-text-galerie p:hover{
    color:#ffffff;
}
.a-b-text-galerie a{
    margin-top:15px ;
}

/* Média */

@media(max-width:1190px){
	#main-galerie{
		background-size: 1150px !important;
		
	}
	.name-galerie{
		left: 50%;
		top: 50%;
        transform: translate(-50%,-50%);
        text-align: center;
    }
    
    .name-galerie h1{
        font-size: 2rem;
    }
    .name-galerie h2{
        font-size: 1.5rem;
    }
    .name-galerie .details{
        font-size: 1rem;
    }

    #features-galerie{
		height:auto;
    }
    .a-container-galerie{
		flex-wrap: wrap;
	}
    .a-box-galerie{
		flex-grow: 1;
	}
    .a-b-img-galerie img{
		object-fit:contain;
    }
}

@media(max-width:970px){
	#main-galerie{
		height: 650px;
	}
	.name-galerie{
		left: 50%;
		top: 50%;
		transform: translate(-50%,-50%);
    }
    
    #features-galerie{
        margin-bottom: 100px;
    }
}
@media(max-width:600px){
	.name-galerie{
		width:60%;
    }
}

